import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import { GOOGLE_LOGIN_SUCCESS } from '../../redux/authentication/const';
import { setError } from '../../error/action';

const googleLoginRedirectEpic = (action$) =>
    action$.pipe(
        ofType(GOOGLE_LOGIN_SUCCESS),
        tap(() => setError()),
        ignoreElements()
    );

export default combineEpics(googleLoginRedirectEpic);
