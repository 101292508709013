import { fromJS } from 'immutable';
import { SET_TEACHER, GET_TEACHER } from './const';
export const TEACHER_PROFILE_REDUCER_NAME = 'teacherProfile';

const initialState = fromJS({
    teacher: null,
});

export const teacherProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEACHER:
            return state.set('teacher', null);
        case SET_TEACHER:
            return state.set('teacher', fromJS(action.teacher));
        default:
            return state;
    }
};
