import { Navigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { useEffect } from 'react';
import { finishPaymentEvent } from '../utils/analytics';
import routes from '../constant/routes';

const PaymentRedirect = () => {
    const price = useSearchParam('price');
    const reservationId = useSearchParam('reservation_id');

    useEffect(() => {
        if (price && reservationId) {
            finishPaymentEvent(reservationId, price);
        }
    }, [price, reservationId]);

    return <Navigate to={routes.USER_LESSON_LIST} />;
};

export default PaymentRedirect;
