import theme from '../views/landing/config/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';

const MuiProvider = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default MuiProvider;
