import { fromJS } from 'immutable';
import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, AUTH_FAILURE } from './const';
export const AUTH_REDUCER_NAME = 'auth';

const initialState = fromJS({
    user: null,
    token: null,
    isLoggedIn: null,
});

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_FAILURE:
            return state.set('user', null).set('token', null).set('isLoggedIn', false);
        case LOGIN_FAILURE:
            return state.set('user', null).set('token', null).set('isLoggedIn', false);
        case LOGIN_SUCCESS:
            return state.set('isLoggedIn', true);
        case LOGOUT:
            return state.set('isLoggedIn', false).set('token', null);
        default:
            return state;
    }
};
