export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';

export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';

export const GOOGLE_LOGIN_REQUEST = 'auth/GOOGLE_LOGIN_REQUEST';

export const GOOGLE_LOGIN_SUCCESS = 'auth/GOOGLE_LOGIN_SUCCESS';

export const LOGOUT = 'auth/LOGOUT';

export const REGISTER_REQUEST = 'auth/REGISTER_REQUEST';

export const REGISTER_FAILURE = 'auth/REGISTER_FAILURE';

export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';

export const INIT_AUTH = 'auth/INIT_AUTH';

export const AUTH_FAILURE = 'auth/AUTH_FAILURE';
