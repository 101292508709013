import { GET_TEACHER_LIST, SET_TEACHER_LIST } from './const';

export const getTeacherList = (searchParams) => {
    return {
        type: GET_TEACHER_LIST,
        searchParams,
    };
};

export const setTeacherList = (teacherList) => {
    return {
        type: SET_TEACHER_LIST,
        teacherList,
    };
};
