import createHistory from 'history/createBrowserHistory';
import routes from '../constant/routes';
import { ga } from '../utils/analytics';

const history = createHistory();

const historyStack = [];

history.listen((path) => {
    historyStack.push(path.pathname);
    ga && ga.pageview(path.pathname);
});

history.goBackSafe = () => {
    let len = historyStack.length;

    const path = historyStack[len - 1];

    while (len > 0 && path === historyStack[len - 1]) {
        len -= 1;
        historyStack.pop();
    }

    if (len > 0) {
        history.replace(historyStack[len - 1]);
    } else {
        history.replace(routes.FRONT_PAGE);
    }
};

export default history;
