import client from '../client';

const API_URL = `/api/auth`;

class AuthService {
    async login({ email, password }) {
        const uri = `${API_URL}/login/`;

        const data = {
            email,
            password,
        };

        return client
            .post(uri, data)
            .then((response) => {
                if (response.data.success) {
                    return {
                        success: true,
                        user: {
                            token: response.data.token,
                        },
                    };
                } else {
                    return {
                        success: false,
                        data: {
                            message: response.data.message,
                        },
                    };
                }
            })
            .catch(() => {
                return {
                    success: false,
                };
            });
    }

    async googleLogin(response) {
        const uri = `${API_URL}/google-login/`;
        const data = {
            token: response.accessToken,
            trust_token: response.trustToken ? response.trustToken : null,
        };

        return client.post(uri, data).then((response) => ({
            user: {
                token: response.data.token,
            },
        }));
    }

    logout() {
        client.removeToken();
    }

    async register({ email, password, name, surname, trustToken, offerRedirect }) {
        const uri = `${API_URL}/signup/`;

        const data = {
            email,
            password,
            first_name: name,
            last_name: surname,
            trust_token: trustToken ? trustToken : undefined,
            offer_redirect: offerRedirect,
        };

        return client.post(uri, data).then((response) => {
            return {
                success: response.data.success,
                data: {
                    email: response.data.email,
                },
            };
        });
    }

    async initAuth() {
        if (!client.getToken()) {
            throw {};
        }

        const uri = `${API_URL}/auth/`;
        return client
            .get(uri)
            .then((response) => ({
                user: {
                    token: response.data.token,
                },
            }))
            .catch((error) => {
                client.removeToken();
                throw error;
            });
    }

    async verifyEmail(verificationCode) {
        const uri = `${API_URL}/verify-email/${verificationCode}/`;
        return client.post(uri, {});
    }

    async requestPasswordChangeCode(email) {
        const uri = `${API_URL}/change-password/send-code/`;
        const data = { email: email };

        return client.post(uri, data);
    }

    async resetPassword(resetCode, newPassword) {
        const uri = `${API_URL}/change-password/apply-code/`;

        const data = { code: resetCode, new_password: newPassword };

        return client.post(uri, data);
    }
}

export default new AuthService();
