import { CLEAR_FORM_FLAGS, SET_LOGIN_FAILURE, SET_LOGIN_PENDING } from './const';

export const clearFormFlags = () => {
    return {
        type: CLEAR_FORM_FLAGS,
    };
};

export const setLoginPending = (pending) => {
    return {
        type: SET_LOGIN_PENDING,
        pending,
    };
};

export const setLoginFailure = (failure) => {
    return {
        type: SET_LOGIN_FAILURE,
        failure,
    };
};
