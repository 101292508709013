const env = { ...import.meta.env, ...window.env };

export const CAPTCHA_KEY = env.REACT_APP_CAPTCHA_KEY;

export const TOKEN_KEY = env.REACT_APP_TOKEN_KEY;

export const BACKEND_URL = env.REACT_APP_BACKEND_URL;

export const GOOGLE_CLIENT_ID = env.REACT_APP_GOOGLE_CLIENT_ID;

export const DEBUG = env.REACT_APP_DEBUG;

export const ENV = env.REACT_APP_ENV;

export const LOC = 'Warszawa, Targówek';

export const COOKIES_SETTINGS_KEY = env.REACT_APP_COOKIES_SETTINGS_KEY;

export const GTM_TAG = env.REACT_APP_GTM_TAG;

export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN;
