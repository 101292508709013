import client from '../client';
import { keysToCamel } from '../utils/camel-snake';

const API_URL = `/api/teacher`;

type CalendarInterval = {
    start: number;
    end: number;
    free: boolean;
};

type GetTeacherListParams = {
    slider?: boolean;
    page?: number;
    pageSize?: number;
};

type PaginatedResponse<T> = {
    count: number;
    results: T[];
};

type Offer = {
    subject: {
        id: number;
        name: string;
    };
    scope: {
        id: number;
        name: string;
    };
    price: number;
};

type OnlinePlatform = {
    id: number;
    name: string;
};

type Discount = {
    type: string;
    size: number;
    maxClassDuration: number;
};

type TeacherProfile = {
    teacher: {
        id: number;
        userId: number;
        email: string;
        name: string;
        surname: string;
        img?: string;
        location: string;
        about: string;
        teachesOffline: boolean;
        platforms: OnlinePlatform[];
        offerList: Offer[];
        ratingAvg: number;
        ratingCount: number;
        is_business: boolean;
        photos: string[];
        hasPhoneNumber: boolean;
        discount?: Discount;
    };
    calendar: {
        intervals: CalendarInterval[];
        intervalsFrom: number;
        intervalsTo: number;
        id: number;
    };
};

export type TeacherListed = {
    user: {
        id: number;
        firstName: string;
        lastName: string;
    };
    descriptionShort: string;
    image: string;
    lastFreeSlot: number;
    ratingGrade: number;
    ratingCount: number;
    offer: Offer[];
    onlinePlatforms: OnlinePlatform[];
    discount: Discount;
};

type GetProfileInput = {
    id: number;
    calendarFrom: number;
    calendarTo: number;
};

export type TeacherPhoneNumber = {
    phoneNumber: string;
};

class TeacherService {
    async profile({ id, calendarFrom, calendarTo }: GetProfileInput): Promise<TeacherProfile> {
        const uri = `${API_URL}/profile/${id}/${calendarFrom}/${calendarTo}/`;

        const response = await client.get(uri);

        const teacher = response.data.teacher;
        const calendar = response.data.calendar;
        const photos = response.data.photos;

        return {
            teacher: {
                id: teacher.id,
                userId: teacher.user.id,
                email: teacher.user.username,
                name: teacher.user.first_name,
                surname: teacher.user.last_name,
                img: teacher.image,
                platforms: teacher.online_platforms,
                location: teacher.location,
                about: teacher.description,
                offerList: teacher.offer,
                teachesOffline: teacher.teaches_offline,
                ratingAvg: teacher.rating_grade,
                ratingCount: teacher.rating_count,
                is_business: teacher.is_business,
                hasPhoneNumber: teacher.has_phone_number,
                discount: teacher.discount
                    ? {
                          type: teacher.discount.type,
                          size: teacher.discount.size,
                          maxClassDuration: teacher.discount.max_class_duration,
                      }
                    : undefined,
                photos: photos,
            },
            calendar: {
                intervals: calendar,
                intervalsFrom: calendarFrom,
                intervalsTo: calendarTo,
                id,
            },
        };
    }

    async getByToken(token: string) {
        const response = await client.get(`${API_URL}/token/${token}/`);
        const teacher = response.data;
        return {
            name: teacher.user.first_name,
            surname: teacher.user.last_name,
            img: teacher.image,
            id: teacher.id,
        };
    }

    async getList({
        page,
        pageSize,
        slider,
    }: GetTeacherListParams): Promise<PaginatedResponse<TeacherListed>> {
        const response = await client.get(`${API_URL}/all/`, {
            page,
            page_size: pageSize,
            slider,
        });
        // @ts-ignore
        return keysToCamel(response.data);
    }

    async getRatingList(teacherId) {
        const response = await client.get(`${API_URL}/rating/${teacherId}`);
        return keysToCamel(response.data);
    }

    async displayPhone(teacherId: number): Promise<TeacherPhoneNumber> {
        const response = await client.post(`${API_URL}/display-phone/${teacherId}/`);
        // @ts-ignore
        return keysToCamel(response.data);
    }
}

export default new TeacherService();
