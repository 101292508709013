import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { GET_TEACHER_LIST } from './const';
import lessonService from '../../services/lesson.service';
import { setTeacherList } from './action';
import { setCalendars } from '../calendar/action';
import { setError } from '../../error/action';

const teacherListEpic = (action$) =>
    action$.pipe(
        ofType(GET_TEACHER_LIST),
        switchMap((action) =>
            from(lessonService.list(action.searchParams)).pipe(
                mergeMap((response) => [
                    setTeacherList(response.teacherList),
                    setCalendars(response.calendarList),
                ]),
                catchError(() => of(setError('')))
            )
        )
    );

export default combineEpics(teacherListEpic);
