import { Cookies } from 'react-cookie';
import { COOKIES_SETTINGS_KEY } from '../constant';

const getCookiesSettings = () => {
    const cookies = new Cookies();
    const settings = cookies.get(COOKIES_SETTINGS_KEY);
    return typeof settings === 'object' ? settings : {};
};

export default getCookiesSettings;
