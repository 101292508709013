import React, { useEffect, Suspense, lazy } from 'react';
import routes from './constant/routes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import history from './custom_history';
import PrivateRoute from './containers/private-route';
import { GlobalStyle } from './styles/global-styles';
import { ClipLoader } from 'react-spinners';

import PaymentRedirect from './views/payment-redirect';
import { isLoggedInSelector } from './redux/authentication/selectors';
import { initAuth as initAuthAction } from './redux/authentication/action';
import { useDispatch, useSelector } from 'react-redux';
import { errorSelector } from './error/selectors';
import { setError } from './error/action';



const ViewLoaderWrapper = styled.div`
    padding: 100px 0;
    display: flex;
    justify-content: center;
`;

const ViewClipLoader = () => {
    return (
        <ViewLoaderWrapper>
            <ClipLoader />
        </ViewLoaderWrapper>
    );
};

const LazyComponent =
    (Component, fallback = <ViewClipLoader />) =>
    (props) =>
        (
            <Suspense fallback={fallback}>
                <Component {...props} />
            </Suspense>
        );

const SearchResult = LazyComponent(lazy(() => import('./views/search-result')));
const TeacherProfile = LazyComponent(lazy(() => import('./containers/teacher-profile')));
const LessonOrder = LazyComponent(lazy(() => import('./containers/lesson-order')));
const Login = LazyComponent(lazy(() => import('./views/login')));
const Register = LazyComponent(lazy(() => import('./containers/register')));
const UserProfile = LazyComponent(lazy(() => import('./views/user-profile')));
const UserLessons = LazyComponent(lazy(() => import('./views/user-lessons')));
const ReservationSummary = LazyComponent(lazy(() => import('./views/reservation-summary')));
const Page404 = LazyComponent(lazy(() => import('./views/404-page')));
const Panel = LazyComponent(lazy(() => import('./views/panel')));
const Pdf = LazyComponent(lazy(() => import('./views/pdf')));
const ErrorPage = LazyComponent(lazy(() => import('./views/error-page')));
const MarkPopup = LazyComponent(
    lazy(() => import('./containers/mark-popup')),
    null
);
const ChatView = LazyComponent(lazy(() => import('./views/chat-view')));
const ChatListView = LazyComponent(lazy(() => import('./views/chat-list-view')));
const EmailVerification = LazyComponent(lazy(() => import('./containers/email-verification')));
const Teachers = LazyComponent(lazy(() => import('./views/teachers')));
const ForStudent = LazyComponent(lazy(() => import('./views/for-student')));
const CookieLawBanner = LazyComponent(
    lazy(() => import('./containers/cookie-law-banner')),
    null
);
const ResetPassword = LazyComponent(lazy(() => import('./views/reset-password-view')));
const StepSearch = LazyComponent(lazy(() => import('./views/step-search')));
const Home = LazyComponent(lazy(() => import('./views/landing')));
const AppWrapper = styled.div``;
const LessonReminder = LazyComponent(lazy(() => import('./components/lesson-reminder')));

const App = () => {
    const isLoggedIn = useSelector(isLoggedInSelector);
    const error = useSelector(errorSelector);
    const dispatch = useDispatch();
    const initAuth = () => dispatch(initAuthAction());
    const nullError = () => dispatch(setError(null));

    useEffect(() => {
        if (isLoggedIn === null) {
            initAuth();
        }
    }, [error]);

    history.listen(nullError);

    return (
        <AppWrapper>
            <GlobalStyle />
            <div id="popup-root" />
            {isLoggedIn !== null && (
                <BrowserRouter history={history}>
                    {error != null && <ErrorPage />}
                    {error === null && (
                        <Routes>
                            <Route exact path={routes.FRONT_PAGE} element={<Home />} />

                            <Route exact path={routes.SEARCH_RESULT} element={<SearchResult />} />

                            <Route exact path={routes.TEACHER} element={<TeacherProfile />} />

                            <Route exact path={routes.FOR_STUDENT} element={<ForStudent />} />

                            <Route exact path={routes.LOGIN} element={<Login />} />
                            <Route exact path={routes.TOKEN_REGISTER} element={<Register />} />

                            <Route exact path={routes.REGISTER} element={<Register />} />

                            <Route exact path={routes.LESSON} element={<LessonOrder />} />

                            <Route
                                exact
                                path={routes.STATUTE}
                                element={<Pdf pdfName={'statue'} />}
                            />

                            <Route
                                exact
                                path={routes.PRIVACY_POLICY}
                                element={<Pdf pdfName={'privacy'} />}
                            />

                            <Route
                                exact
                                path={routes.COOKIES}
                                element={<Pdf pdfName={'cookies'} />}
                            />

                            <Route
                                exact
                                path={routes.VERIFY_EMAIL}
                                element={<EmailVerification />}
                            />

                            <Route exact path={routes.TEACHERS} element={<Teachers />} />

                            <Route exact path={routes.PANEL} element={<Panel />} />

                            <Route exact path={routes.RESET_PASSWORD} element={<ResetPassword />} />
                            <Route path={routes.STEP_SEARCH}>
                                <Route index element={<StepSearch />} />
                                <Route path=":domain/" element={<StepSearch />} />
                                <Route path=":domain/:level" element={<StepSearch />} />
                            </Route>

                            <Route
                                exact
                                path={routes.CHAT}
                                element={
                                    <PrivateRoute>
                                        <ChatView />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                exact
                                path={routes.CHAT_LIST}
                                element={
                                    <PrivateRoute>
                                        <ChatListView />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                exact
                                path={routes.USER_PROFILE}
                                element={
                                    <PrivateRoute>
                                        <UserProfile />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                exact
                                path={routes.RESERVATION_SUMMARY}
                                element={
                                    <PrivateRoute>
                                        <ReservationSummary />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                exact
                                path={routes.USER_LESSON_LIST}
                                element={
                                    <PrivateRoute>
                                        <UserLessons />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                exact
                                path={routes.PAYMENT_REDIRECT}
                                element={<PaymentRedirect />}
                            />
                            <Route path={'*'} element={<Page404 />} />
                        </Routes>
                    )}
                    <CookieLawBanner />
                </BrowserRouter>
            )}
            {isLoggedIn && (
                <>
                    <MarkPopup />
                    <LessonReminder />
                </>
            )}
        </AppWrapper>
    );
};

export default App;
