import {
    GET_LESSON_TO_MARK,
    SET_LESSON_MARK,
    SET_LESSON_MARK_REQUEST_TIME,
    SET_LESSON_TO_MARK,
    SET_MARK_SUCCESS,
} from './const';

export const getLessonToMark = (lessonToMark) => {
    return {
        type: GET_LESSON_TO_MARK,
        lessonToMark,
    };
};

export const setLessonToMark = (lessonToMark) => {
    return {
        type: SET_LESSON_TO_MARK,
        lessonToMark,
    };
};

export const setLessonMark = (lessonMark) => {
    return {
        type: SET_LESSON_MARK,
        lessonMark,
    };
};

export const setMarkSuccess = (success) => {
    return {
        type: SET_MARK_SUCCESS,
        success,
    };
};

export const setLessonMarkRequestTime = (time) => {
    return {
        type: SET_LESSON_MARK_REQUEST_TIME,
        time,
    };
};
