import { createSelector } from 'reselect';
import { prop } from 'ramda';
import { AUTH_REDUCER_NAME } from './reducer';

const getAuthReducerState = prop(AUTH_REDUCER_NAME);

export const userSelector = createSelector(getAuthReducerState, (authState) =>
    authState.get('user')
);

export const isLoggedInSelector = createSelector(getAuthReducerState, (authState) =>
    authState.get('isLoggedIn')
);

export const tokenSelector = createSelector(getAuthReducerState, (authState) =>
    authState.get('token')
);

export const loginFailureSelector = createSelector(getAuthReducerState, (authState) =>
    authState.get('loginFailure')
);

export const loginPendingSelector = createSelector(getAuthReducerState, (authState) =>
    authState.get('loginPending')
);
