import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { switchMap, map, delay } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { GET_LESSON_TO_MARK, SET_LESSON_MARK, SET_LESSON_MARK_REQUEST_TIME } from './const';
import userService from '../../services/user.service';
import { setLessonToMark, setMarkSuccess, getLessonToMark } from './action';
import { setError } from '../../error/action';

const getLessonToMarkEpic = (action$) =>
    action$.pipe(
        ofType(GET_LESSON_TO_MARK),
        switchMap((action) =>
            from(userService.lessonToMark()).pipe(
                map((response) => {
                    if (response.success) {
                        return setLessonToMark(response.lessonToMark);
                    } else {
                        return setError('');
                    }
                })
            )
        )
    );

const retryGetLessonToMark = (action$) =>
    action$.pipe(
        ofType(GET_LESSON_TO_MARK),
        switchMap(() => of(getLessonToMark()).pipe(delay(15 * 60 * 1000)))
    );

const setLessonToMarkRequestTime = (action$) =>
    action$.pipe(
        ofType(SET_LESSON_MARK_REQUEST_TIME),
        switchMap((action) => of(getLessonToMark()).pipe(delay(Date.now() - action.time)))
    );

const setLessonMarkEpic = (action$) =>
    action$.pipe(
        ofType(SET_LESSON_MARK),
        switchMap((action) =>
            from(userService.mark(action.lessonMark)).pipe(
                map((response) => {
                    if (response.success) {
                        window.location.reload();
                        return setMarkSuccess(true);
                    } else {
                        return setError('');
                    }
                })
            )
        )
    );

export default combineEpics(
    getLessonToMarkEpic,
    setLessonMarkEpic,
    retryGetLessonToMark,
    setLessonToMarkRequestTime
);
