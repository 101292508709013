export const camelToSnakeCase = (camelString: string): string =>
    camelString.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (snakeString: string): string =>
    snakeString.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StringKeyObject = { [key: string]: any };

const mapObjectKeys = (
    object: StringKeyObject | StringKeyObject[],
    mapper: (key: string) => string
): StringKeyObject | StringKeyObject[] => {
    if (Array.isArray(object)) {
        return object.map((value) => mapObjectKeys(value, mapper));
    }
    if (object !== undefined && object !== null && object.constructor === Object) {
        return Object.keys(object).reduce(
            (result, key) => ({
                ...result,
                [mapper(key)]: mapObjectKeys(object[key], mapper),
            }),
            {}
        );
    }
    return object;
};

export const keysToCamel = (object: object) => mapObjectKeys(object, snakeToCamelCase);

export const keysToSnake = (object: object) => mapObjectKeys(object, camelToSnakeCase);
