import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { switchMap, map, ignoreElements, tap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LOGIN_REQUEST, REGISTER_REQUEST, GOOGLE_LOGIN_REQUEST, LOGOUT, INIT_AUTH } from './const';
import {
    loginFailure,
    loginSuccess,
    registerFailure,
    registerSuccess,
    authFailure,
} from './action';
import authService from '../../services/auth.service';
import routes from '../../constant/routes';
import history from '../../custom_history';
import { setError } from '../../error/action';

const loginEpic = (action$) =>
    action$.pipe(
        ofType(LOGIN_REQUEST),

        switchMap((action) =>
            from(authService.login(action.userData)).pipe(
                map((response) => {
                    if (response.success) {
                        return loginSuccess(response.user);
                    } else {
                        return loginFailure(response.data);
                    }
                })
            )
        )
    );

const registerEpic = (action$) =>
    action$.pipe(
        ofType(REGISTER_REQUEST),
        switchMap((action) =>
            from(authService.register(action.userData)).pipe(
                map((response) => registerSuccess(response.data)),
                catchError((error) => {
                    const response = error.response;
                    if (response.status !== 400) {
                        return of(setError(''));
                    } else {
                        return of(registerFailure(response.data));
                    }
                })
            )
        )
    );

const googleLoginEpic = (action$) =>
    action$.pipe(
        ofType(GOOGLE_LOGIN_REQUEST),
        switchMap((action) =>
            from(authService.googleLogin(action.userData)).pipe(
                map((response) => loginSuccess(response)),
                catchError((error) =>
                    of(
                        error.response.status === 400
                            ? registerFailure(error.response.data.details)
                            : setError('')
                    )
                )
            )
        )
    );

const logoutEpic = (action$) =>
    action$.pipe(
        ofType(LOGOUT),
        tap(() => authService.logout() || history.push(routes.FRONT_PAGE)),
        ignoreElements()
    );

const initAuthEpic = (action$) =>
    action$.pipe(
        ofType(INIT_AUTH),
        switchMap(() =>
            from(authService.initAuth()).pipe(
                map((response) => loginSuccess(response.user)),
                catchError((err) =>
                    of(!err.response || err.response.status === 401 ? authFailure() : setError(''))
                )
            )
        )
    );

export default combineEpics(loginEpic, registerEpic, googleLoginEpic, logoutEpic, initAuthEpic);
