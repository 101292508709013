import { fromJS } from 'immutable';
import { SET_TEACHER_BY_TOKEN, GET_TEACHER_BY_TOKEN } from './const';
export const TEACHER_BY_TOKEN_REDUCER_NAME = 'teacherByToken';

const initialState = fromJS({
    teacher: null,
});

export const teacherByTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEACHER_BY_TOKEN:
            return state.set('teacher', null);
        case SET_TEACHER_BY_TOKEN:
            return state.set('teacher', fromJS(action.teacher));
        default:
            return state;
    }
};
