import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { mergeMap, map } from 'rxjs/operators';
import { from } from 'rxjs';
import { GET_INTERVALS } from './const';
import lessonService from '../../services/lesson.service';
import { updateCalendar } from './action';
import { setError } from '../../error/action';

const getIntervalsEpic = (action$) =>
    action$.pipe(
        ofType(GET_INTERVALS),
        mergeMap((action) =>
            from(lessonService.calendar(action.searchParams)).pipe(
                map((response) => {
                    if (response.success) {
                        return updateCalendar(response.calendar);
                    } else {
                        return setError('');
                    }
                })
            )
        )
    );

export default combineEpics(getIntervalsEpic);
