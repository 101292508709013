import { ENV, GTM_TAG, SENTRY_DSN } from '../constant';

import TagManager from 'react-gtm-module';
import { init, BrowserTracing, reactRouterV6Instrumentation } from '@sentry/react';

import React from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

export let ga = null;

const putAnalyticsEvent = ({ event, ...other }) => {
    const data = {
        event,
        ...other,
    };

    if (ENV !== 'PROD') {
        console.debug('GTM EVENT', data);
    }

    window.dataLayer?.push({
        event,
        ...other,
    });
};

const setupSentry = () => {
    if (!SENTRY_DSN) {
        return;
    }

    init({
        dsn: SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/corasy-backend-dev\.railway\.app/,
            /^https:\/\/corasy-backend-prod\.railway\.app/,
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

const setupGTM = () => {
    if (!GTM_TAG) {
        return;
    }
    TagManager.initialize({
        gtmId: GTM_TAG,
    });
};

export const setupAnalytics = (cookiesSettings) => {
    if (!cookiesSettings.analytics) {
        return;
    }

    setupSentry();
    setupGTM();
};

export const registerEvent = (other = {}) => {
    putAnalyticsEvent({
        category: 'account',
        action: 'register',
        event: 'user_registered',
        ...other,
    });
};

export const emailVerifyEvent = () => {
    putAnalyticsEvent({
        event: 'email_verified',
        category: 'account',
        action: 'email_verify',
    });
};

export const loginEvent = (withEmail, other = {}) => {
    putAnalyticsEvent({
        event: 'logged_in',
        category: 'account',
        action: 'login',
        with_email: withEmail,
        ...other,
    });
};

export const startPaymentEvent = (reservationId, price) => {
    putAnalyticsEvent({
        event: 'started_payment',
        category: 'order',
        action: 'pay',
        reservation_id: reservationId,
        reservation_price: price,
    });
};

export const finishPaymentEvent = (reservationId, price) => {
    putAnalyticsEvent({
        event: 'finished_payment',
        category: 'order',
        action: 'pay',
        reservation_id: reservationId,
        reservation_price: price,
    });
};

export const reserveEvent = (other = {}) => {
    putAnalyticsEvent({
        event: 'reserved_lesson',
        category: 'order',
        action: 'reserve',
        ...other,
    });
};

export const cancelEvent = (reservationId) => {
    putAnalyticsEvent({
        event: 'cancelled_lesson',
        category: 'order',
        action: 'cancel',
        reservation_id: reservationId,
    });
};

export const joinLessonEvent = (reservationId) => {
    putAnalyticsEvent({
        event: 'joined_lesson',
        category: 'order',
        action: 'start',
        reservation_id: reservationId,
    });
};

export const pageViewEvent = (url, title, other = {}) => {
    putAnalyticsEvent({
        event: 'page_viewed',
        category: 'engagement',
        action: 'page_view',
        url,
        title,
        ...other,
    });
};

export const searchEvent = (domain, level, date, other = {}) => {
    putAnalyticsEvent({
        event: 'searched_lesson',
        category: 'engagement',
        action: 'search',
        domain_id: domain,
        level_id: level,
        date: date,
        ...other,
    });
};

export const clickEvent = (elementName, other = {}) => {
    putAnalyticsEvent({
        event: 'element_clicked',
        category: 'engagement',
        action: 'click',
        'gtm.element': elementName,
        ...other,
    });
};

export const displayPhoneEvent = (teacherId, other = {}) => {
    putAnalyticsEvent({
        event: 'phone_displayed',
        category: 'engagement',
        action: 'display_phone',
        teacher_id: teacherId,
        ...other,
    });
};
