import { fromJS } from 'immutable';
import { GET_INTERVALS, SET_CALENDARS, UPDATE_CALENDAR } from './const';
export const CALENDAR_REDUCER_NAME = 'calendar';

const initialState = fromJS({
    calendars: {},
    requests: {},
});

export const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CALENDAR:
            const calendar = action.calendar;
            const intervalsFrom = calendar.intervalsFrom;
            const intervalsTo = calendar.intervalsTo;
            const intervals = calendar.intervals;

            return state.updateIn(['calendars', calendar.id.toString()], (calendar) =>
                calendar
                    .update('intervalsFrom', (date) => Math.min(date, intervalsFrom))
                    .update('intervalsTo', (date) => Math.max(date, intervalsTo))
                    .update('intervals', (list) => list.concat(fromJS(intervals)))
            );

        case SET_CALENDARS:
            const calendarList = action.calendarList;
            let newState = fromJS({
                calendars: {},
                requests: {},
            });

            calendarList &&
                calendarList.forEach((calendar) => {
                    newState = newState.setIn(
                        ['calendars', calendar.id.toString()],
                        fromJS(calendar)
                    );
                });

            return newState;
        case GET_INTERVALS:
            const dateFrom = action.searchParams.dateFrom;
            const dateTo = action.searchParams.dateTo;
            const id = action.searchParams.teacherId.toString();

            if (state.getIn(['requests', id])) {
                return state.updateIn(['requests', id], (requests) =>
                    requests
                        .update('from', (from) => Math.min(dateFrom, from))
                        .update('to', (to) => Math.max(dateTo, to))
                );
            } else {
                return state.setIn(
                    ['requests', id],
                    fromJS({
                        from: dateFrom,
                        to: dateTo,
                    })
                );
            }

        default:
            return state;
    }
};
