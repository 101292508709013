import { fromJS } from 'immutable';
import { SET_ERROR } from './const';
export const ERROR_REDUCER_NAME = 'errorSummary';

const initialState = fromJS({
    error: null,
});

export const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR:
            return state.set('error', fromJS(action.error));
        default:
            return state;
    }
};
