import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { switchMap, map, mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { GET_TEACHER } from './const';
import teacherService from '../../services/teacher.service';
import { setTeacher } from './action';
import { setCalendars } from '../calendar/action';
import { setError } from '../../error/action';

const teacherEpic = (action$) =>
    action$.pipe(
        ofType(GET_TEACHER),
        map((action) => action.searchParams),
        switchMap((searchParams) =>
            from(teacherService.profile(searchParams)).pipe(
                mergeMap((response) => [
                    setTeacher(response.teacher),
                    setCalendars([response.calendar]),
                ]),
                catchError(() => of(setError('')))
            )
        )
    );

export default combineEpics(teacherEpic);
