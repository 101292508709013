import axios from 'axios';
import { BACKEND_URL } from '../constant';
import { saveToken, getToken, removeToken } from './token';

class ApiClient {
    constructor() {
        this.instance = axios.create();

        this.token = getToken();

        this.instance.interceptors.request.use(
            (config) => {
                config.headers = {
                    'Content-Type': 'application/json',
                };

                if (this.token) {
                    config.headers['Authorization'] = `Bearer ${this.token}`;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (response) => {
                if (response.headers['x-access-token']) {
                    saveToken(response.headers['x-access-token']);
                    this.token = response.headers['x-access-token'];
                }

                return response;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    get(uri, params) {
        return this.instance.get(`${BACKEND_URL}${uri}`, {
            params,
        });
    }

    post(uri, data) {
        return this.instance.post(`${BACKEND_URL}${uri}`, data);
    }

    removeToken() {
        removeToken();

        this.token = null;
    }

    getToken() {
        return this.token;
    }
}

export default new ApiClient();
