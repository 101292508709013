import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../constant/routes';
import { isLoggedInSelector } from '../../redux/authentication/selectors';
import { Route } from 'react-router-dom';

const PrivateRouteContainer = (props) => {
    if (!props.isLoggedIn) {
        return <Navigate to={routes.LOGIN} />;
    }

    return props.children;
};

const mapStateToProps = (state) => ({
    isLoggedIn: isLoggedInSelector(state),
});

export default connect(mapStateToProps)(PrivateRouteContainer);
