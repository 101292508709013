import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import createReducer from './reducer';
import { rootEpic } from './epic';
import { DEBUG, ENV, GOOGLE_CLIENT_ID } from './constant';
import { CookiesProvider } from 'react-cookie';
import HttpsRedirect from 'react-https-redirect';
import getCookiesSettings from './utils/cookies-settings';
import { setupAnalytics } from './utils/analytics';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import MuiProvider from './components/mui-provider';
import { GoogleOAuthProvider } from '@react-oauth/google';

let store, composeEnhancers;

const reducer = createReducer();

const epicMiddleware = createEpicMiddleware();

const middleware = applyMiddleware(epicMiddleware);

if (DEBUG) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

store = createStore(reducer, undefined, composeEnhancers(middleware));

epicMiddleware.run(rootEpic);

const Security = ENV === 'PROD' ? HttpsRedirect : React.Fragment;

setupAnalytics(getCookiesSettings());

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Security>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Provider store={store}>
                <MuiProvider>
                    <QueryClientProvider client={queryClient}>
                        <CookiesProvider>
                            <React.StrictMode>
                                <App />
                            </React.StrictMode>
                        </CookiesProvider>
                    </QueryClientProvider>
                </MuiProvider>
            </Provider>
        </GoogleOAuthProvider>
    </Security>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
