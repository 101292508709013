import { combineEpics, ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { LOGIN_FAILURE, LOGIN_REQUEST } from '../../redux/authentication/const';
import { setLoginFailure, setLoginPending } from './actions';

const loginFailureEpic = (action$) =>
    action$.pipe(
        ofType(LOGIN_FAILURE),
        mergeMap(() => [setLoginFailure(true), setLoginPending(false)])
    );

const loginPendingEpic = (action$) =>
    action$.pipe(
        ofType(LOGIN_REQUEST),
        map(() => setLoginPending(true))
    );

export default combineEpics(loginFailureEpic, loginPendingEpic);
