const routes = {
    FRONT_PAGE: '/',
    LOGIN: '/zaloguj',
    REGISTER: '/dolacz',
    TOKEN_REGISTER: '/dolacz/zaufany/:token',
    SEARCH_RESULT: '/szukaj/:domain/:level/:date',
    TEACHER: '/korepetytor/:id',
    ABOUT: '/o-nas',
    USER_PROFILE: '/konto',
    LESSON: '/zajecia/:teacher/:domain/:level/:date',
    USER_LESSON_LIST: '/moje-zajecia',
    RESERVATION_SUMMARY: '/podsumowanie-rezerwacji/:reservation_id',
    STATUTE: '/regulamin',
    PRIVACY_POLICY: '/polityka-prywatnosci',
    PANEL: '/panel',
    VERIFY_EMAIL: '/zweryfikuj',
    COOKIES: '/polityka-cookies',
    FOR_STUDENT: '/dla-ucznia',
    TEACHERS: '/korepetytorzy',
    CHAT_LIST: '/wiadomosci',
    CHAT: '/wiadomosci/:user_id',
    RESET_PASSWORD: '/resetuj-haslo',
    STEP_SEARCH: '/szukaj',
    PAYMENT_REDIRECT: '/platnosc-udana',
};

export default routes;
