import { combineEpics } from 'redux-observable';
import teacherListEpic from './containers/teacher-list/epic';
import teacherProfileEpic from './containers/teacher-profile/epic';
import authEpic from './redux/authentication/epic';
import loginFormEpic from './containers/login-form/epic';
import registerFormEpic from './containers/register-form/epic';
import socialLoginEpic from './containers/social-login/epic';
import calendarEpic from './containers/calendar/epic';
import markPopupEpic from './containers/mark-popup/epic';

export const rootEpic = combineEpics(
    teacherListEpic,
    teacherProfileEpic,
    authEpic,
    loginFormEpic,
    registerFormEpic,
    socialLoginEpic,
    calendarEpic,
    markPopupEpic
);
