import { GET_TEACHER, SET_TEACHER } from './const';

export const getTeacher = (searchParams) => {
    return {
        type: GET_TEACHER,
        searchParams,
    };
};

export const setTeacher = (teacher) => {
    return {
        type: SET_TEACHER,
        teacher,
    };
};
