import { CLEAR_FORM_FLAGS, SET_REGISTER_SUCCESS, SET_REGISTER_PENDING } from './const';

export const clearFormFlags = () => {
    return {
        type: CLEAR_FORM_FLAGS,
    };
};

export const setRegisterPending = (pending) => {
    return {
        type: SET_REGISTER_PENDING,
        pending,
    };
};

export const setRegisterSuccess = (payload) => {
    return {
        type: SET_REGISTER_SUCCESS,
        payload,
    };
};
