import client from '../client';

const API_URL = `/api/student`;

class UserService {
    async profile() {
        const uri = `${API_URL}/profile/`;
        const response = await client.get(uri);
        return response.data.userProfile;
    }

    async lessons() {
        const uri = `${API_URL}/lessons/`;
        const response = await client.get(uri);
        return response.data.lesson_list;
    }

    async modify(data) {
        const uri = `${API_URL}/modify/`;
        return client.post(uri, data);
    }

    mark({ lessonId, comment, grade, isAnonymous }) {
        const uri = `${API_URL}/rating/`;
        const data = {
            reservation: lessonId,
            comment,
            grade,
            is_anonymous: isAnonymous,
        };

        return client
            .post(uri, data)
            .then((response) => {
                return {
                    success: true,
                };
            })
            .catch((error) => {
                return {
                    success: false,
                };
            });
    }

    async upcomingLesson() {
        const uri = `${API_URL}/upcoming-lesson/`;
        const data = await client.get(uri);
        return data.data.lesson;
    }

    async lessonToMark() {
        const uri = `${API_URL}/rating/`;
        return client
            .get(uri)
            .then((response) => {
                const reservation = response.data.reservation;
                if (!reservation) {
                    return {
                        lessonToMark: null,
                        success: true,
                    };
                }
                const teacher = reservation.skill.teacher;
                const domain = reservation.skill.subject;
                return {
                    success: true,
                    lessonToMark: {
                        teacher: {
                            name: teacher.user.first_name,
                            surname: teacher.user.last_name,
                            image: teacher.image,
                        },
                        domain: domain.name,
                        lessonId: reservation.pk,
                        start: reservation.start,
                        end: reservation.end,
                    },
                };
            })
            .catch((error) => {
                return {
                    success: false,
                };
            });
    }
}

export default new UserService();
