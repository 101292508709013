import {
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    REGISTER_REQUEST,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    LOGOUT,
    INIT_AUTH,
    AUTH_FAILURE,
} from './const';

export const loginRequest = (userData) => {
    return {
        type: LOGIN_REQUEST,
        userData,
    };
};

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user,
    };
};

export const googleLoginRequest = (userData) => {
    return {
        type: GOOGLE_LOGIN_REQUEST,
        userData,
    };
};

export const googleLoginSuccess = (user) => {
    return {
        type: GOOGLE_LOGIN_SUCCESS,
        user,
    };
};

export const loginFailure = (payload) => {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
};

export const registerRequest = (userData) => {
    return {
        type: REGISTER_REQUEST,
        userData,
    };
};

export const registerFailure = (payload) => {
    return {
        type: REGISTER_FAILURE,
        payload,
    };
};

export const registerSuccess = (payload) => {
    return {
        type: REGISTER_SUCCESS,
        payload,
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
    };
};

export const initAuth = () => {
    return {
        type: INIT_AUTH,
    };
};

export const authFailure = () => {
    return {
        type: AUTH_FAILURE,
    };
};
