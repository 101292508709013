import { fromJS } from 'immutable';
import { CLEAR_FORM_FLAGS, SET_REGISTER_PENDING, SET_REGISTER_SUCCESS } from './const';
import { REGISTER_FAILURE } from '../../redux/authentication/const';

export const REGISTER_FORM_REDUCER_NAME = 'registerForm';

const initialState = fromJS({
    registerPending: false,
    registerSuccess: undefined,
    registerErrors: {},
});

export const registerFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REGISTER_PENDING:
            return state.set('registerPending', action.pending);
        case SET_REGISTER_SUCCESS:
            return state.set('registerSuccess', action.payload);
        case REGISTER_FAILURE:
            return state.set('registerErrors', action.payload);
        case CLEAR_FORM_FLAGS:
            return state.set('registerPending', false).set('registerFailure', false);
        default:
            return state;
    }
};
