import { SET_CALENDARS, GET_INTERVALS, UPDATE_CALENDAR } from './const';

export const setCalendars = (calendarList) => {
    return {
        type: SET_CALENDARS,
        calendarList,
    };
};

export const updateCalendar = (calendar) => {
    return {
        type: UPDATE_CALENDAR,
        calendar,
    };
};

export const getIntervals = (searchParams) => {
    return {
        type: GET_INTERVALS,
        searchParams,
    };
};
