import { fromJS } from 'immutable';
import { CLEAR_FORM_FLAGS, SET_LOGIN_PENDING, SET_LOGIN_FAILURE } from './const';
export const LOGIN_FORM_REDUCER_NAME = 'loginForm';

const initialState = fromJS({
    loginPending: false,
    loginFailure: false,
});

export const loginFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_FORM_FLAGS:
            return state.set('loginPending', false).set('loginFailure', false);
        case SET_LOGIN_PENDING:
            return state.set('loginPending', action.pending);
        case SET_LOGIN_FAILURE:
            return state.set('loginFailure', action.failure);
        default:
            return state;
    }
};
