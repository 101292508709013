import { combineReducers } from 'redux';

import { TEACHER_LIST_REDUCER_NAME, teacherListReducer } from './containers/teacher-list/reducer';

import {
    TEACHER_PROFILE_REDUCER_NAME,
    teacherProfileReducer,
} from './containers/teacher-profile/reducer';

import {
    TEACHER_BY_TOKEN_REDUCER_NAME,
    teacherByTokenReducer,
} from './containers/register/reducer';

import { AUTH_REDUCER_NAME, authReducer } from './redux/authentication/reducer';

import {
    REGISTER_FORM_REDUCER_NAME,
    registerFormReducer,
} from './containers/register-form/reducer';

import { CALENDAR_REDUCER_NAME, calendarReducer } from './containers/calendar/reducer';

import { LOGIN_FORM_REDUCER_NAME, loginFormReducer } from './containers/login-form/reducer';

import { ERROR_REDUCER_NAME, errorReducer } from './error/reducer';

import { LESSON_TO_MARK_REDUCER_NAME, lessonToMarkReducer } from './containers/mark-popup/reducer';

const createReducer = () => {
    return combineReducers({
        [TEACHER_LIST_REDUCER_NAME]: teacherListReducer,
        [TEACHER_PROFILE_REDUCER_NAME]: teacherProfileReducer,
        [AUTH_REDUCER_NAME]: authReducer,
        [REGISTER_FORM_REDUCER_NAME]: registerFormReducer,
        [LOGIN_FORM_REDUCER_NAME]: loginFormReducer,
        [CALENDAR_REDUCER_NAME]: calendarReducer,
        [TEACHER_BY_TOKEN_REDUCER_NAME]: teacherByTokenReducer,
        [ERROR_REDUCER_NAME]: errorReducer,
        [LESSON_TO_MARK_REDUCER_NAME]: lessonToMarkReducer,
    });
};

export default createReducer;
