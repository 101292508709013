import client from '../client';

const API_URL = `/api/lesson`;

class LessonService {
    async domainList() {
        const uri = `${API_URL}/offer-list/`;

        return client.get(uri).then((response) => {
            const data = response.data;

            const domains = data.subject_list.map((subject) => ({
                id: subject.id,
                displayName: subject.name,
            }));

            const levels = data.scope_list.map((scope) => ({
                id: scope.id,
                displayName: scope.name,
            }));

            return {
                domains,
                levels,
            };
        });
    }

    async lesson({ teacher, date, domain, level }) {
        const uri = `${API_URL}/lesson/${teacher}/${domain}/${level}/${date}/`;

        return client.get(uri).then((response) => {
            const data = response.data;
            const teacher = data.teacher;
            const offer = data.offer;
            const discount = data.discount;

            return {
                teacher: {
                    id: teacher.id,
                    userId: teacher.user.id,
                    name: teacher.user.first_name,
                    surname: teacher.user.last_name,
                    img: teacher.image,
                    platforms: teacher.online_platforms,
                    location: teacher.location,
                    teachesOffline: teacher.teaches_offline,
                    ratingAvg: teacher.rating_grade,
                    ratingCount: teacher.rating_count,
                },
                offer: {
                    domain: offer.subject_name,
                    level: offer.scope_name,
                    price: offer.price,
                    id: offer.id,
                },
                discount: discount,
                slotList: data.calendar,
                date: parseInt(date),
            };
        });
    }

    async calendar({ teacherId, dateFrom, dateTo }) {
        const uri = `${API_URL}/class-list/${teacherId}/${dateFrom}/${dateTo}/`;

        return client
            .get(uri)
            .then((response) => {
                const data = response.data;
                return {
                    success: true,

                    calendar: {
                        id: teacherId,
                        intervals: data.class_list,
                        intervalsFrom: dateFrom,
                        intervalsTo: dateTo,
                    },

                    firstFreeSlot: data.first_free_slot,
                    lastFreeSlot: data.last_free_slot,
                };
            })
            .catch(() => {
                return {
                    success: false,
                };
            });
    }

    async lessonSummary({ reservationId }) {
        const uri = `${API_URL}/lesson/${reservationId}/`;

        return client
            .get(uri)
            .then((response) => {
                const data = response.data;
                const teacher = data.teacher;
                const offer = data.offer;
                const discount = data.discount;

                return {
                    success: true,
                    lesson: {
                        teacher: {
                            id: teacher.id,
                            name: teacher.user.first_name,
                            surname: teacher.user.last_name,
                            img: teacher.image,
                            toolList: teacher.tool_list,
                            teachesOffline: teacher.teaches_offline,
                        },
                        offer: {
                            domain: offer.subject_name,
                            level: offer.scope_name,
                            price: offer.price,
                            id: offer.id,
                        },
                        discount: discount,
                    },
                };
            })
            .catch(() => {
                return {
                    success: false,
                };
            });
    }

    async list({ dateFrom, dateTo, level, domain, dateMain }) {
        const uri = `${API_URL}/list/${domain}/${level}/${dateFrom}/${dateTo}/${dateMain}/`;

        return client.get(uri).then((response) => {
            const data = response.data;
            const teacherList = data.map((teacher) => ({
                id: teacher.id,
                userId: teacher.user.id,
                name: teacher.user.first_name,
                surname: teacher.user.last_name,
                calendar: teacher.calendar,
                img: teacher.image_small,
                price: teacher.price,
                platforms: teacher.online_platforms,
                location: teacher.location,
                discount: teacher.discount,
                ratingAvg: teacher.rating_grade,
                ratingCount: teacher.rating_count,
                description: teacher.description_short,
                hasPhoneNumber: teacher.has_phone_number,
                lowestPriceMonthly: teacher.skill_lowest_price_monthly,
            }));

            const calendarList = data.map((teacher) => ({
                id: teacher.id,
                intervals: teacher.calendar,
                intervalsFrom: dateFrom,
                intervalsTo: dateTo,
            }));

            return {
                teacherList,
                calendarList,
            };
        });
    }

    async summary(reservationId) {
        const uri = `${API_URL}/order-summary/${reservationId}/`;
        const response = await client.get(uri);
        return response.data.summary;
    }

    async order({
        lessonStart,
        lessonEnd,
        scope,
        subject,
        comment,
        is_offline,
        online_platform_id,
        teacher,
    }) {
        const data = {
            scope_id: scope,
            subject_id: subject,
            teacher_id: teacher,
            lesson_start: lessonStart,
            lesson_end: lessonEnd,
            student_comment: comment,
            is_offline: is_offline,
            online_platform_id: online_platform_id,
        };

        const uri = `${API_URL}/order-submit/`;
        return client.post(uri, data).then((response) => {
            return {
                success: true,
                reservation: response.data.reservation_id,
            };
        });
    }

    async pay(orderId) {
        const uri = `${API_URL}/pay/${orderId}/`;
        const response = await client.get(uri);
        return response.data.tpay_link;
    }

    async cancel(orderId) {
        const uri = `${API_URL}/cancel/${orderId}/`;
        const response = await client.post(uri);
        return response.data;
    }

    async join(reservationId) {
        const uri = `${API_URL}/join/${reservationId}/`;
        const response = await client.post(uri);
        return response.data;
    }
}

export default new LessonService();
