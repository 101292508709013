import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, tap, mergeMap, map } from 'rxjs/operators';
import {
    REGISTER_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
} from '../../redux/authentication/const';

import { setRegisterPending, setRegisterSuccess } from './actions';
import { loginSuccess } from '../../redux/authentication/action';

const registerSuccessEpic = (action$) =>
    action$.pipe(
        ofType(REGISTER_SUCCESS),
        mergeMap(() => [setRegisterPending(false), setRegisterSuccess(true), loginSuccess()])
    );

const registerFailureEpic = (action$) =>
    action$.pipe(
        ofType(REGISTER_FAILURE),
        mergeMap((action) => [setRegisterPending(false), setRegisterSuccess(false)])
    );

const registerRequestEpic = (action$) =>
    action$.pipe(
        ofType(REGISTER_REQUEST),
        mergeMap(() => [setRegisterPending(true), setRegisterSuccess(undefined)])
    );

export default combineEpics(registerSuccessEpic, registerFailureEpic, registerRequestEpic);
