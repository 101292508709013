import { PaletteOptions } from '@mui/material';

const paletteBase: Partial<PaletteOptions> = {
    primary: {
        light: '#9FF1D2',
        main: '#00AFF5',
        dark: '#00AFF5',
        contrastText: '#fbfbfb',
    },
    secondary: {
        light: '#FDE6A8',
        main: '#ffaf35',
        dark: '#D0821C',
        contrastText: '#fbfbfb',
    },
};

export default paletteBase;
