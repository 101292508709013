import { fromJS } from 'immutable';
import { SET_LESSON_TO_MARK, SET_MARK_SUCCESS } from './const';

export const LESSON_TO_MARK_REDUCER_NAME = 'lessonToMark';

const initialState = fromJS({
    lessonMark: false,
    lessonMarked: false,
});

export const lessonToMarkReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LESSON_TO_MARK:
            return state.set('lessonToMark', fromJS(action.lessonToMark));
        case SET_MARK_SUCCESS:
            return state.set('lessonMarked', fromJS(action.success));
        default:
            return state;
    }
};
