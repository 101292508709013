import { fromJS } from 'immutable';
import { SET_TEACHER_LIST, GET_TEACHER_LIST } from './const';
export const TEACHER_LIST_REDUCER_NAME = 'teacherList';

const initialState = fromJS({
    teacherList: null,
});

export const teacherListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEACHER_LIST:
            return state.set('teacherList', null);
        case SET_TEACHER_LIST:
            return state.set('teacherList', fromJS(action.teacherList));
        default:
            return state;
    }
};
